import "rsuite/styles/index.less";
import "./App.css";
import Fade from "react-reveal/Fade";
import { Divider, Panel } from "rsuite";

export function Aboutme() {
  return (
    <div className="ContentMain">
      <Fade>
        <h2>Technical Competencies</h2>

        <div style={{ minWidth: "40vw" }}>
          <Divider />
          <Panel className="Panel" header={<h3>Areas of Expertise</h3>} collapsible>
            <p>Embedded Programming</p>
            <p>Data Engineering</p>
            <p>Machine Learning</p>
            <p>Frontend Engineering</p>
            <p>Backend Engineering</p>
          </Panel>
          <Panel className="Panel" header={<h3>Coding Languages</h3>} collapsible>
            <p>Javascript</p>
            <p>Python</p>
            <p>Matlab</p>
            <p>C</p>
            <p>HTML/CSS</p>
          </Panel>

          <Panel className="Panel" header={<h3>Frameworks/Libraries</h3>} collapsible>
            <p>Arduino</p>
            <p>Pandas</p>
            <p>sci.py</p>
            <p>TensorFlow</p>
            <p>React.js</p>
            <p>express.js</p>
          </Panel>

          <Panel className="Panel" header={<h3>Other Competencies</h3>} collapsible>
            <p>Version Control Systems & Providers (git, GitHub, BitBucket)</p>
            <p>DevOps (Jenkins, Bitbucket Pipelines)</p>
            <p>Google Firebase</p>
            <p>Microsoft Azure APIs</p>
            <p>Game specific APIs (World of Warcraft, League of Legends)</p>
          </Panel>
          <Divider />
        </div>

        <h2>Short Bio</h2>
        <h3>"Knowledge is power"</h3>
        <div className="ContentSec">
          <p>
            I am, have been and always will be (hopefully) a staunch believer in the
            hypothesis that intelligence and wit gives way to optimisation and innovation,
            the two key aspects that make the world go round. Ever since I was little, I
            was always curious about the inner workings of all kinds of toys and things I
            used on a daily basis.
          </p>
          <hr />
          <p>
            The satisfaction of understanding simple and/or complex know-hows about the
            life factors surrounding me was something that I was always interested in
            proactively chasing. Naturally, given that I always was attracted to the
            intellectual side of problem solving. This, together with my passion for
            electric guitar made me pick up electrical engineering as my go-to degree in
            unversity, as well as later on, through my PhD work. However, as time went on,
            my interests have moved slightly towards more abstractised problem solving, as
            I kept on pursuing more complex challenges.
          </p>
          <hr />
          <p>
            This is what fueled my ambition to learn machine learning in the first place.
            The prospect of automation through human-like learning and showing the results
            through inuitive dynamic designs is quite exciting. Beyond the pragmatic
            coding side of it, I believe there is also an philosophical argument that
            could be made towards bettering your own thinking, involving pushing your
            cognitive abilities to the maximum, finding your inner limits and breaking
            them wide open.
          </p>
          <hr />
          <p>
            I have then discovered that machine learning alone cannot necessarily be a
            gamechanger for the grander public. Data and understanding that data is good,
            but it doesn't mean much as long as you're not able to display your
            conclusions in a meaningful, simple, robust and intuitive way. This is when my
            interests have gained traction in the area of frontend engineering, having
            recognised this as a powerful tool for displaying the abilities of your
            algorithms.
          </p>
          <hr />
          <p>
            If you've actually got all the way here, here's an easter egg. During my free
            time I'm always up for a good workout session or a music jam (my passion for
            music never left, in fact!). I'm also quite keen on following motorsports and
            wise financial investments.
          </p>
        </div>
      </Fade>
    </div>
  );
}
