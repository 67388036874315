import "rsuite/styles/index.less";
import "./App.css";
import Fade from "react-reveal/Fade";
import * as faReact from "@fortawesome/free-brands-svg-icons/faReact";
import * as faHtml5 from "@fortawesome/free-brands-svg-icons/faHtml5";
import * as faCss3 from "@fortawesome/free-brands-svg-icons/faCss3";
import * as faJs from "@fortawesome/free-brands-svg-icons/faJs";
import * as faGithub from "@fortawesome/free-brands-svg-icons/faGithub";
import React from "react";
import { Divider, Panel } from "rsuite";
import Icon from "@rsuite/icons/lib/Icon";

const FaSvgIcon = ({ faIcon, ...rest }) => {
  const { width, height, svgPathData } = faIcon;
  return (
    <svg
      {...rest}
      viewBox={`0 0 ${width} ${height}`}
      width="2em"
      height="2em"
      fill="currentColor"
    >
      <path d={svgPathData}></path>
    </svg>
  );
};

export function Projects() {
  const svgIconMUI = React.forwardRef((props, ref) => (
    <svg
      {...props}
      width="24px"
      height="24px"
      viewBox="0 0 2em 2em"
      transform="translate(2,0)"
      style={{ webkitTransform: "translate(2px, 0px)" }}
      color="black"
    >
      <path d="M0 2.475v10.39l3 1.733V7.67l6 3.465 6-3.465v3.465l-6 3.463v3.464l6 3.463 9-5.195V9.402l-3 1.733v3.463l-6 3.464-3-1.732 6-3.465V2.475L9 7.67 0 2.475zm24 0l-3 1.73V7.67l3-1.732V2.474Z" />
    </svg>
  ));

  const svgIconFirebase = React.forwardRef((props, ref) => (
    <svg
      width="32px"
      height="32px"
      viewBox="0 0 512 512"
      id="icons"
      fill="black"
      transform="translate(-5,13)"
      style={{ webkitTransform: "translate(-5px, 13px)" }}
    >
      <path
        id="icon"
        d="M93.19,329.38,140.64,25.31c1.64-10.37,15.55-12.82,20.46-3.55l51,95.45ZM432,400,385.26,123.21a11,11,0,0,0-18.54-6L80,400l159.36,91.91a33.18,33.18,0,0,0,31.91,0ZM302.36,158.93,265.82,89.39a10.86,10.86,0,0,0-19.36,0L85.83,375.74Z"
      />
    </svg>
  ));

  const svgIconAzure = React.forwardRef((props, ref) => (
    <svg
      width="25px"
      height="25px"
      viewBox="0 0 96 96"
      transform="translate(2,10)"
      style={{ webkitTransform: "translate(2px, 10px)" }}
      fill="black"
    >
      <g transform="translate(2,10)"></g>
      <path d="M33.338 6.544h26.038l-27.03 80.087a4.152 4.152 0 0 1-3.933 2.824H8.149a4.145 4.145 0 0 1-3.928-5.47L29.404 9.368a4.152 4.152 0 0 1 3.934-2.825z" />
      <path d="M33.338 6.544a4.118 4.118 0 0 0-3.943 2.879L4.252 83.917a4.14 4.14 0 0 0 3.908 5.538h20.787a4.443 4.443 0 0 0 3.41-2.9l5.014-14.777 17.91 16.705a4.237 4.237 0 0 0 2.666.972H81.24L71.024 60.261l-29.781.007L59.47 6.544z" />
      <path d="M66.595 9.364a4.145 4.145 0 0 0-3.928-2.82H33.648a4.146 4.146 0 0 1 3.928 2.82l25.184 74.62a4.146 4.146 0 0 1-3.928 5.472h29.02a4.146 4.146 0 0 0 3.927-5.472z" />
    </svg>
  ));

  const RSuiteLogoSvg = React.forwardRef((props, ref) => (
    <svg
      {...props}
      viewBox="0 0 120 138"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width="2em"
      height="2em"
      ref={ref}
    >
      <title>React Suite</title>
      <defs>
        <linearGradient
          x1="71.5906675%"
          y1="12.5658792%"
          x2="45.577567%"
          y2="114.749969%"
          id="linearGradient-1"
        >
          <stop stopColor="#001122" offset="0%"></stop>
          <stop stopColor="#001122" offset="100%"></stop>
        </linearGradient>
        <linearGradient
          x1="67.6269531%"
          y1="0%"
          x2="50%"
          y2="78.0639648%"
          id="linearGradient-2"
        >
          <stop stopColor="#001122" offset="0%"></stop>
          <stop stopColor="#001122" offset="100%"></stop>
        </linearGradient>
        <linearGradient
          x1="67.6269531%"
          y1="0%"
          x2="50%"
          y2="79.2449951%"
          id="linearGradient-3"
        >
          <stop stopColor="#001122" offset="0%"></stop>
          <stop stopColor="#001122" offset="100%"></stop>
        </linearGradient>
      </defs>
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Group-22" transform="translate(3.000000, 6.000000)">
          <polyline
            className="polyline-axis"
            stroke="url(#linearGradient-1)"
            strokeWidth="12"
            strokeLinecap="round"
            strokeLinejoin="round"
            points="111 31 57 0 19 22 95 104 57 126 3 95"
          ></polyline>
          <polyline
            className="polyline-limb"
            id="Path-5-Copy-7"
            stroke="url(#linearGradient-2)"
            strokeWidth="12"
            strokeLinecap="round"
            strokeLinejoin="round"
            transform="translate(22.000000, 63.000000) scale(-1, -1) translate(-22.000000, -63.000000) "
            points="41 31 3 54 41 95 41 52"
          ></polyline>
          <polyline
            className="polyline-limb"
            stroke="url(#linearGradient-3)"
            strokeWidth="12"
            strokeLinecap="round"
            strokeLinejoin="round"
            points="111 31 73 54 111 95 111 52"
          ></polyline>
          <circle className="circle" fill="#001122" cx="3" cy="95" r="3"></circle>
          <circle fill="#001122" cx="111" cy="31" r="3"></circle>
        </g>
      </g>
    </svg>
  ));
  return (
    <div className="ContentMain">
      <Fade>
        <div>
          <h4>
            I have always been an adept of learning concepts through hands-on experience
            and direct applications of the theory models behind.
          </h4>
          <hr />
          <p>
            Below you will find a short portfolio of brief example frontend applications I enjoyed working on,
            as well as a link to live versions of them.
          </p>
          <Divider />
          <Panel
            className="Panel"
            header={
              <div>
                <h3>remind-me-now</h3>
                <Icon as={FaSvgIcon} faIcon={faHtml5} style={{ color: "#001122" }} />
                <Icon as={FaSvgIcon} faIcon={faCss3} style={{ color: "#001122" }} />
                <Icon as={FaSvgIcon} faIcon={faJs} style={{ color: "#001122" }} />
                <Icon as={FaSvgIcon} faIcon={faReact} style={{ color: "#001122" }} />
                <Icon as={RSuiteLogoSvg} />
                <Icon as={svgIconFirebase} />
              </div>
            }
            collapsible
            bordered
          >
            <p>
              A service app that allows user to create personal accounts and store to-do
              lists. Functionality includes user account creation and backend mechanics
              through Google firebase, using Firebase Auth and Firestore.
            </p>
            <p>Technologies used include:</p>
            <ul>
              <li>HTML/CSS</li>
              <li>JavaScript</li>
              <li>React (including Rsuite)</li>
            </ul>
            <p>
              A live version of this app can be found{" "}
              <a href="https://romantic-banach-a9a3b4.netlify.app/">here.</a>
            </p>
            <a href="https://github.com/ZollyG/remind-me-now">
              <Icon as={FaSvgIcon} faIcon={faGithub} style={{ color: "#001122" }} />
            </a>
          </Panel>
          <Panel
            className="Panel"
            header={
              <div>
                <h3>5-way tic-tac-toe</h3>
                <Icon as={FaSvgIcon} faIcon={faHtml5} style={{ color: "#001122" }} />
                <Icon as={FaSvgIcon} faIcon={faCss3} style={{ color: "#001122" }} />
                <Icon as={FaSvgIcon} faIcon={faJs} style={{ color: "#001122" }} />
                <Icon as={FaSvgIcon} faIcon={faReact} style={{ color: "#001122" }} />
                <Icon as={svgIconFirebase} />
              </div>
            }
            collapsible
            bordered
          >
            <p>
              A more complex variation of the classic tic-tac-toe game, winning requires
              5-ways instead of 3. Playable on 2 separate terminals/browser instances
              using Firestore read/write and React useEffect dynamics.
            </p>
            <p>Technologies used include:</p>
            <ul>
              <li>HTML/CSS</li>
              <li>JavaScript</li>
              <li>React </li>
            </ul>
            <p>
              A live version of this app can be found{" "}
              <a href="https://upbeat-heisenberg-81f80d.netlify.app/">here.</a>
            </p>
            <a href="https://github.com/ZollyG/tic-tac-toe-4way">
              <Icon as={FaSvgIcon} faIcon={faGithub} style={{ color: "#001122" }} />
            </a>
          </Panel>
          <Panel
            className="Panel"
            header={
              <div>
                <h3 style={{ marginBottom: "1%" }}>blog-platform</h3>
                <Icon as={FaSvgIcon} faIcon={faHtml5} style={{ color: "#001122" }} />
                <Icon as={FaSvgIcon} faIcon={faCss3} style={{ color: "#001122" }} />
                <Icon as={FaSvgIcon} faIcon={faJs} style={{ color: "#001122" }} />
                <Icon as={FaSvgIcon} faIcon={faReact} style={{ color: "#001122" }} />
              </div>
            }
            collapsible
            bordered
          >
            <p>
              A simple mock blog platform which uses browser API local storage
              capabilities to store data.
            </p>
            <p>Technologies used include:</p>
            <ul>
              <li>HTML/CSS</li>
              <li>JavaScript</li>
              <li>React</li>
            </ul>
            <p>
              A live version of this app can be found{" "}
              <a href="https://laughing-beaver-4ed606.netlify.app/">here.</a>
            </p>
            <a href="https://github.com/ZollyG/blog-platform">
              <Icon as={FaSvgIcon} faIcon={faGithub} style={{ color: "#001122" }} />
            </a>
          </Panel>
        </div>
      </Fade>
    </div>
  );
}
