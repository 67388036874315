import "rsuite/styles/index.less";
import "./App.css";
import Fade from "react-reveal/Fade";
import * as faEnvelope from "@fortawesome/free-solid-svg-icons/faEnvelope";
import * as faGithub from "@fortawesome/free-brands-svg-icons/faGithub";
import * as faLinkedin from "@fortawesome/free-brands-svg-icons/faLinkedin";
import { Divider, Panel } from "rsuite";
import Icon from "@rsuite/icons/lib/Icon";

const FaSvgIcon = ({ faIcon, ...rest }) => {
  const { width, height, svgPathData } = faIcon;
  return (
    <svg
      {...rest}
      viewBox={`0 0 ${width} ${height}`}
      width="2em"
      height="2em"
      fill="currentColor"
    >
      <path d={svgPathData}></path>
    </svg>
  );
};

export function Contact() {
  return (
    <div className="ContentMain">
      <Fade>
        <div>
          <h5>
            I'm always open towards learning and improving my current software engineering
            skills, as well as any potential learning and career opportunities.
          </h5>
        </div>

        <div className="ContentSec">
          <p>
            If you'd like to get in touch with me, show me a potential career opportunity
            and/or an interesting challenge that I could assist with or just fancy a chat
            about my competencies and hobbies, I'm just a text message away. You can reach
            me here:
          </p>
          <Divider />
        </div>
        <div className="ContactDetails">
          <div className="PanelContact">
            <Panel
              header={
                <a href="mailto:tudorstincescu96@gmail.com" style={{ color: "#001122" }}>
                  <Icon as={FaSvgIcon} faIcon={faEnvelope} />
                </a>
              }
            >
              <a style={{ color: "#001122" }} href="mailto:tudorstincescu96@gmail.com">
                tudorstincescu96@gmail.com
              </a>
            </Panel>
          </div>

          <div className="PanelContact">
            <Panel
              header={
                <a style={{ color: "#001122" }} href="https://github.com/ZollyG">
                  <Icon as={FaSvgIcon} faIcon={faGithub} />
                </a>
              }
            >
              <a style={{ color: "#001122" }} href="https://github.com/ZollyG">
                github.com/ZollyG
              </a>
            </Panel>
          </div>
          <div className="PanelContact">
            <Panel
              header={
                <a
                  style={{ color: "#001122" }}
                  href="https://www.linkedin.com/in/tudor-stincescu-1abc/"
                >
                  <Icon as={FaSvgIcon} faIcon={faLinkedin} />
                </a>
              }
            >
              <a
                style={{ color: "#001122" }}
                href="https://www.linkedin.com/in/tudor-stincescu-1abc/"
              >
                linkedin.com/in/tudor-stincescu-1abc/
              </a>
            </Panel>
          </div>
        </div>
      </Fade>
    </div>
  );
}
