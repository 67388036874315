import { Timeline, Divider } from "rsuite";
import "rsuite/styles/index.less";
import "./App.css";
import Fade from "react-reveal/Fade";

export function CT() {
  return (
    <div className="ContentMain">
      <Timeline align="left" className="custom-timeline" endless>
      <Fade>
          <Timeline.Item>
            <div>
              <div>
                <h5>
                  Oct 2023 - Present - TDK Electronics GmbH, Software/Firmware R&D Engineer
                </h5>
                <p>Graz, AT</p>
                <Divider />
              </div>
              <div className="TimelineComponent">
                <p>
                  Software and embedded firmware developer for various novel applications and devices. Operating in a pre-product development
                  R&D team, my work focuses on researching, validating, developing and testing new application-focused features for 
                  upcoming products. 
                </p>
                <p>
                  Gained experience with embedded development engineering, particularly with C/C++ and Arduino environments. Further improved scientific computing
                  and application development skills in Matlab and Python. Exposure to embedded development-focused DevOps.
                </p>
              </div>
            </div>
          </Timeline.Item>
        </Fade>
        <Fade>
          <Timeline.Item>
            <div>
              <div>
                <h5>
                  Jun 2019 - Sep 2023 - The Unversity of Sheffield, PhD research in
                  Electrical Engineering & Applied Combinatorial Optimisation
                </h5>
                <p>The University of Sheffield, Sheffield, UK</p>
                <Divider />
              </div>
              <div className="TimelineComponent">
                <h6>
                  Graduated with minor corrections. Research extensively featured in several journals and conferences.
                </h6>
                <p>
                  Thesis topic: Optimisation of grid connected energy storage based on EV
                  battery fleet vehicle operation.
                </p>
                <p>
                  Awarded full-time EPSRC tuition fee and maintenance fee scholarship.
                  Work includes data analysis and software model development through
                  MATLAB/Simulink and Python.
                </p>
              </div>
            </div>
          </Timeline.Item>
        </Fade>
        <Fade>
          <Timeline.Item>
            <div>
              <div>
                <h5>
                  Jul 2020 - Oct 2021 - AlgoFlow, Project Advisor and Consultant to
                  Founding Team
                </h5>
                <p>Bucharest, RO</p>
                <Divider />
              </div>
              <div className="TimelineComponent">
                <p>
                  Assisting the online course creation process and helped develop the
                  services offered by the company. Advising the founding team on multiple
                  soft and technical matters and gave feedback on course material,
                  services development and teaching methodology.
                </p>
                <p>
                  Gained experience with web development software engineering frameworks
                  and SWE industry practices, as well as industry-standard data structures
                  and algorithms.
                </p>
              </div>
            </div>
          </Timeline.Item>
        </Fade>
        <Fade>
          <Timeline.Item>
            <div>
              <div>
                <h5>
                  Oct 2015 - Jun 2019 - The Unversity of Sheffield, BEng Electronics
                  and Communication Engineering
                </h5>
                <h6>Grade: First Class Honours, equivalent to US GPA 3.7+</h6>
                <p>The University of Sheffield, Sheffield, UK</p>
                <Divider />
              </div>
              <div className="TimelineComponent">
                <p>
                  Activities and Societies: Student Member of the IET UK, Student Member
                  of the IEEE.
                </p>
                <p>
                  Courses include knowledge of electrical and electronic circuits,
                  communication devices, control systems, digital signal processing,
                  computer architectures and mathematics (calculus and linear algebra).
                  Awarded EEE excellence scholarship.
                </p>
                <p>
                  Final year project topic - algorithm conditioning and overall
                  optimisation of quasi steady-state and nonlinear programming MATLAB
                  simulation scripts for motorsport hybrid powertrain applications.
                  Performed in collaboration with McLaren Applied Ltd.
                </p>
                <p>Awarded EEE Excellence Scholarship.</p>
              </div>
            </div>
          </Timeline.Item>
        </Fade>
        <Fade>
          <Timeline.Item>
            <div>
              <div>
                <h5>
                  Sep 2017 - Sep 2018 - McLaren Applied, High-Performance Electronics
                  Engineer (Intern)
                </h5>
                <p>McLaren Technology Centre, Woking, UK</p>
                <Divider />
              </div>
              <div className="TimelineComponent">
                <p>
                  Main contributor in a R&D project that concerns the design and build a
                  highly versatile, ultrafast switching Gallium Nitride (GaN) DCDC
                  isolated prototype converter primarily aimed towards automotive and
                  motorsports applications (DCU-200).
                </p>
                <p>
                  The system aims to be significantly smaller than existing Si or SiC
                  alternatives and improve energy transfer performance by minimising
                  system losses in hybrid powertrains similar to the ones used in McLaren
                  P1 or MCL32 (Honda ICE) / MCL33 (Renault ICE) F1 cars.
                </p>
                <p>
                  Work performed in a highly demanding, dynamic and competitive
                  environment. Pioneered the introduction of GaN technology in MAT Power
                  Electronics hardware. Proposed design featured at CENEX LCV Expo 2018.
                </p>
              </div>
            </div>
          </Timeline.Item>
        </Fade>
        <Fade>
          <Timeline.Item>
            <div>
              <div>
                <h5>Jun 2015 - Graduated from "Petru Rares" National College</h5>
                <p>Piatra Neamt, RO</p>
                <Divider />
              </div>
              <p className="TimelineComponent">
                Lead member of the team that won the Zayed Future Energy Prize in 2015
                which promotes student projects that aim to improve the energy consumption
                of their schools, while making it a more eco-friendly place.
              </p>
            </div>
          </Timeline.Item>
        </Fade>
      </Timeline>
    </div>
  );
}
