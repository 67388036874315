import { Nav, Button, Dropdown } from "rsuite";
import "rsuite/styles/index.less";
import "./App.css";
import { Projects } from "./Projects.js";
import { Home } from "./Home.js";
import { Aboutme } from "./Aboutme.js";
import { CT } from "./CT.js";
import { Research } from "./Research.js";
import { Contact } from "./Contact.js";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import favicon from "./graphs/favicon.ico";
import { Helmet } from "react-helmet";
import useWindowDimensions from "./windowDimensions";

function App() {
  let windowDimensions = useWindowDimensions();
  let date = new Date().getFullYear();
  return (
    <div className="App">
      <Helmet>
        <title>Tudor Stincescu - Personal Website</title>
        <meta
          name="Tudor Stincescu - Personal Website"
          content="Tudor Stincescu - Personal Website"
        />
        <link rel="icon" href={favicon} type="image/png" sizes="16x16" />
      </Helmet>
      <Router>
        {windowDimensions.width >= 800 ? (
          <Nav className="NavBar">
            <Link to="/">
              <Button className="NavButton">Home</Button>
            </Link>
            <Link to="/about-me">
              <Button className="NavButton">About me</Button>
            </Link>
            <Link to="/career-timeline">
              <Button className="NavButton">Career Timeline</Button>
            </Link>
            <Link to="/projects">
              <Button className="NavButton">Featured Projects</Button>
            </Link>
            <Link to="/research">
              <Button className="NavButton">Research</Button>
            </Link>
            <Link to="/contact">
              <Button className="NavButton">Contact</Button>
            </Link>
          </Nav>
        ) : (
          <Nav className="NavBarMob">
            <Dropdown
              title={<div style={{ fontSize: "20px", color: "black" }}>Menu</div>}
              style={{ marginTop: "3%", marginBottom: "3%" }}
              className="Dropdown"
            >
              <Link to="/" style={{ textDecoration: "none" }}>
                <Dropdown.Item style={{ color: "black" }}>Home</Dropdown.Item>
              </Link>

              <Link to="/about-me" style={{ textDecoration: "none" }}>
                <Dropdown.Item style={{ color: "black" }}>About me</Dropdown.Item>
              </Link>

              <Link to="/career-timeline" style={{ textDecoration: "none" }}>
                <Dropdown.Item style={{ color: "black" }}>Career Timeline </Dropdown.Item>
              </Link>

              <Link to="/projects" style={{ textDecoration: "none" }}>
                <Dropdown.Item style={{ color: "black" }}>
                  Featured Projects
                </Dropdown.Item>
              </Link>

              <Link to="/research" style={{ textDecoration: "none" }}>
                <Dropdown.Item style={{ color: "black" }}>Research</Dropdown.Item>
              </Link>

              <Link to="/contact" style={{ textDecoration: "none" }}>
                <Dropdown.Item style={{ color: "black" }}>Contact</Dropdown.Item>
              </Link>
            </Dropdown>
          </Nav>
        )}

        <div className="PageContentUpper">
          <Route exact path="/">
            <Home />
          </Route>
          <Route exact path="/about-me">
            <Aboutme />
          </Route>
          <Route exact path="/career-timeline">
            <CT />
          </Route>
          <Route exact path="/projects">
            <Projects />
          </Route>
          <Route exact path="/research">
            <Research />
          </Route>
          <Route exact path="/contact">
            <Contact />
          </Route>
        </div>

        <div className="PageContentLower">
          <div class="custom-shape-divider-bottom-1634721173">
            <svg
              data-type="Layer1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1200 120"
              preserveAspectRatio="none"
            >
              <path
                id="Layer11"
                d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z"
                opacity=".25"
                class="shape-fill"
              ></path>

              <path
                id="Layer21"
                d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z"
                opacity=".5"
                class="shape-fill"
              ></path>

              <path
                id="Layer31"
                d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z"
                class="shape-fill"
              ></path>
            </svg>
          </div>
          <div class="custom-shape-divider-bottom-1634726037"></div>

          <h6 style={{ position: "relative" }}>© {date} Tudor Stincescu</h6>
        </div>
      </Router>
    </div>
  );
}

export default App;
