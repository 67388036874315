import "rsuite/styles/index.less";
import "./App.css";
import Fade from "react-reveal/Fade";
import { Divider, Panel } from "rsuite";

export function Research() {
  return (
    <div className="ContentMain">
      <Fade>
        <div>
          <h3>
            Besides my ongoing career in software engineering, I have also worked extensively worked in academic research
            during my (now graduated) PhD tenure at The University of Sheffield, UK.
          </h3>
          <hr />
          <h6>
            My thesis lies mostly in the areas of Electrical Engineering and Combinatorial
            Optimisation, and it is concerned with optimising the operation of electric
            vehicle fleets through optimisation techniques. The optimisation process is
            performed by developing state-of-the-art simulations through software
            modelling. The work has aided me in perfecting my Python and Matlab coding
            skills, as well as gaining a solid understanding of combinatorial mathematics
            and process parallelisation in simulation work.
          </h6>
          <hr />
          <p>
            The project is looking at a wide range of possible optimisations for different
            categories of electric powertrains, ranging from lightweight electrified
            motorbikes to electric public service vehicles and trucks. Most of the
            research work that I have carried out in the years since I have joined as a
            PhD researcher has been successfully published and featured in academic
            journals and conferences. You can find a list of all publications to date
            below.
          </p>
        </div>

        <div className="ResearchPanels">
          <Divider />
          <div>
            <Panel className="Panel" header={<h3>Conferences</h3>}>
              <ul style={{ maxWidth: "90vw", textAlign: "justify" }}>
                <li>
                  <b>T. Stincescu</b>, R. Zhao, E. E. F. Ballantyne, D. A. Stone,
                  "Modelling Energy Use in an Electric Refuse Collection Vehicle using a
                  Novel Interval Method", Logistics Research Network Conference (LRN) 2020
                </li>
                <li>
                  <b>T. Stincescu</b>, R. Zhao, E. E. F. Ballantyne, D. A. Stone and M. P.
                  Foster, "Matlab-based High-Performance Electric Motorbike Energy Model,
                  Utilising Video Data," 2021 Sixteenth International Conference on
                  Ecological Vehicles and Renewable Energies (EVER), 2021, pp. 1-5, doi:
                  10.1109/EVER52347.2021.9456609.
                </li>
                <li>
                  <b>T. Stincescu</b>, R. Zhao, E. E. F. Ballantyne, D. A. Stone, "
                  Understanding Fleet Energy Use of Electric Refuse Collection Vehicles in
                  Urban Environments", Logistics Research Network Conference (LRN) 2021
                </li>
                <li>
                  R. Zhao,<b> T. Stincescu</b>, E. E. F. Ballantyne, D. A. Stone and M. P.
                  Foster, "Dashcam Video-Driven, Route Distinctive Energy Consumption
                  Pre-Evaluation for Electrifying Public Services Fleets," 2021 Sixteenth
                  International Conference on Ecological Vehicles and Renewable Energies
                  (EVER), 2021, pp. 1-5, doi: 10.1109/EVER52347.2021.9456625.
                </li>
                <li>
                  R. Zhao, <b>T.Stincescu</b>, E. E. F. Ballantyne, D. A. Stone, " Energy
                  Usage & Sustainability prediction for Electrified Public Service
                  Vehicles (PSVs) – A City Distinctive Pre-Deployment Solution ",
                  Logistics Research Network Conference (LRN) 2021, Cardiff University, UK
                </li>
                <li>
                  <b>T.Stincescu</b>, E. E. F. Ballantyne, D. A. Stone, " Assessing Energy
                  Usage of Public Service Vehicles in Urban Areas", EURO 2022 Conference,
                  Aalto University, Espoo, Finland
                </li>
                <li>
                  <b>T.Stincescu</b>, E. E. F. Ballantyne, D. A. Stone, " Pallet-sized,
                  Hot-swappable Battery Packs for Electric HGV Range Extension ",
                  Logistics Research Network Conference (LRN) 2022, Aston University,
                  Birmingham, UK
                </li>
              </ul>
            </Panel>
            <Panel className="Panel" header={<h3>Academic Journals</h3>}>
              <ul style={{ maxWidth: "90vw", textAlign: "justify" }}>
                <li>
                  R. Zhao, <b>T. Stincescu</b>, E.E.F. Ballantyne, D.A. Stone,
                  "Sustainable City: Energy Usage Prediction Method for Electrified Refuse
                  Collection Vehicles." Smart Cities 2020, 3, 1100-1116.
                  <a href="https://doi.org/10.3390/smartcities3030054">
                    https://doi.org/10.3390/smartcities3030054
                  </a>
                </li>
                <li>
                  G.W.M. Hind, E.E.F. Ballantyne, <b>T. Stincescu</b>, R. Zhao, D.A. Stone,
                  Extracting dashcam telemetry data for predicting energy use of electric vehicles,
                  Transportation Research Interdisciplinary Perspectives,
                  Volume 27, 2024, 101189, ISSN 2590-1982.
                <a href = "https://doi.org/10.1016/j.trip.2024.101189" />
                </li>
                <li>
                  <b>T. Stincescu</b>, R. Zhao, E. E. F. Ballantyne, D. A. Stone, "
                  Optimising Number of Refuse Collection Stops per Street for Maximising
                  Energy Efficiency of an Electric Refuse Collection Vehicle”
                </li>
              </ul>
            </Panel>
          </div>
        </div>
      </Fade>
    </div>
  );
}
