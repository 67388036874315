import "rsuite/styles/index.less";
import "./App.css";
import Fade from "react-reveal/Fade";

export function Home() {
  return (
    <div className="ContentMain">
      <Fade>
        <h3>Hello Internet</h3>
        <div className="ContentSec">
          <h5>
            My name is Tudor Stincescu, I'm a software developer with skills in embedded development, 
            data engineering and frontend/backend technologies.
          </h5>
          <h5>
            Throughout my industry, academic and entrepreneurial careers so far, I have predominantly worked
            in the field of R&D engineering, spanning several development areas such as
            embedded development, simulation engineering, automotive engineering and web development.
          </h5>
          <hr />
          <h5>
            This website serves as a "story log" of my journey so far and also includes some of my favourite
            projects in my portfolio.
          </h5>
        </div>
      </Fade>
    </div>
  );
}
